import React from 'react'
//import { Link } from 'gatsby'
//import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import styles from "./profile.module.css"

const ProfilePage = () => (
	<Layout>
	<SEO title="プロフィール" />
		<div className={styles.container}>
			<h1>プロフィール</h1>
			<dl className={styles.profile}>
				<dt>名前</dt>
					<dd>金子 正人 (かねこ まさと)</dd>
				<dt>職種</dt>
					<dd>ITインフラ・エンジニア</dd>
				<dt>専門領域</dt>
					<dd>ITインフラの構築・運用、情報セキュリティ対策の普及・啓発</dd>
				<dt>出身</dt>
					<dd>埼玉県さいたま市</dd>
				<dt>在住</dt>
					<dd>宮城県仙台市</dd>
				<dt>趣味</dt>
					<dd>バドミントン、ランニング、DIY など</dd>
			</dl>

			<h2>About</h2>
			<p>
			埼玉県出身、宮城県在住のフリーランスITエンジニア。
			大学発ITベンチャー企業でITインフラ・エンジニアとして、顧客のサーバやネットワークといった、業務の基盤となるITインフラの構築・運用に従事。
			その傍ら、2013年からは情報セキュリティ担当を兼務し、情報セキュリティに関する相談やコンサルティングなども対応。
			また、情報セキュリティを題材とした課外授業を大学の外部講師として担当し、実習形式の授業を大学生を対象に6年間実施。
			その他、経済産業省所管の独立行政法人が主催する高度IT人材育成・発掘事業の一環として、事業の修了生を対象に、情報セキュリティに関連する基礎知識の拡充やフォローアップを目的とした講座の講師を2年間担当。
			2019年にフリーランスITエンジニアとして独立・開業。
			</p>

			<h2>主なスキルセット</h2>
			<h3>Linux や FreeBSD を用いた各種サーバの構築・運用</h3>
			<p>
			オンプレミス、およびクラウド環境でのウェブサーバ、メールサーバ (SMTP/POP3/IMAP)、ネームサーバ (DNS)、データベースサーバ (MySQL/PostgreSQL) 等の構築・運用。
			Linux は主に Debian GNU/Linux を利用。
			</p>

			<h3>業務用ネットワーク機器を用いたネットワークの構築・運用</h3>
			<p>
			インターネット接続環境、多拠点間でのVPN、無線LAN (Wi-Fi) 環境の構築・運用。
			主に NEC や HP(HPE)、 Cisco や YAMAHA、 NETGEAR といったベンダーのルータ、スイッチ、無線LANアクセスポイント等を利用。
			</p>

			<h3>CTF等のセキュリティコンテストへの参加経験</h3>
			<p>
			脆弱性実証コード (エクスプロイト) の作成、セキュリティ上の弱点を持つシステムの堅牢化など
			</p>

			<h3>プログラミングスキル</h3>
			<p>
			主な利用言語は、次の通りです。
			</p>
			<ul className={styles.lang}>
				<li>シェルスクリプト</li>
				<li>Python</li>
				<li>JavaScript</li>
				<li>HTML5</li>
				<li>CSS3</li>
			</ul>
		</div>
	</Layout>
)

export default ProfilePage

/* [EOF] */
